<template>
  <b-link class="brand-logo">
    <logo :width="200" :height="200" :isWhite="isWhite" />
  </b-link>
</template>

<script>
import { BLink } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import Logo from "@/layouts/components/Logo.vue";

export default {
  components: {
    BLink,
    Logo,
  },
  props: {
    isWhite: {
      type: Boolean,
      required: false,
      defaultValue: false,
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
    };
  },
};
</script>
